interface Props {
  title: string
}
export const H3: React.FC<Props> = ({ title }) => {
  return (
    <h3 className="p-4 my-10 mx-[-10px] text-xl leading-[1.4] text-white bg-[#4a85c7]">
      {title}
    </h3>
  )
}

/* eslint tailwindcss/no-custom-classname: off */
import {
  LatestSummary,
  RankingSummary,
  Recommend,
  Summary,
  TopRecommendSummary,
} from '@/types/summary'
import { Summary as SummaryMd } from '@/types/business_group'
import { SummaryButton } from '@/components/summary/SummaryButton'

interface Props {
  summary:
    | Summary
    | SummaryMd
    | LatestSummary
    | TopRecommendSummary
    | RankingSummary
    | Recommend
}
export const SummaryDetail: React.FC<Props> = ({ summary }) => {
  return (
    <div className="mt-3 w-full align-top">
      <div className="mb-2 text-[15px] font-medium leading-snug text-fl-black1">
        {summary.title}
      </div>
      <div className="mb-2 text-[13px] leading-snug text-fl-sky1">
        {summary.authors?.map((author) => (
          <span className="pr5" key={author.id}>
            {author.name}
          </span>
        ))}
      </div>
      <SummaryButton summary={summary} />
    </div>
  )
}

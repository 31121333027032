interface Props {
  className?: string
  title: string
  showShadowTitle?: boolean
}
export const H1: React.FC<Props> = ({
  className,
  title,
  showShadowTitle = true,
}) => {
  return (
    <h1 className={`text-[24px] text-fl-black ${className || ''}`}>
      <span className={`text-center ${showShadowTitle && 'shadow-title'}`}>
        {title}
      </span>
    </h1>
  )
}

interface Props {
  className: string
  text: string
}

export const Chip: React.FC<Props> = ({ className, text }) => {
  return (
    <div
      className={`inline-block py-[2px] px-1 w-10 h-[22px] text-[10px] font-bold text-center align-middle ${className}`}
    >
      {text}
    </div>
  )
}

/* eslint tailwindcss/no-custom-classname: off */
interface Props {
  isDisplay: boolean
  show_underline_1: boolean
  show_underline_2: boolean
  show_underline_3: boolean
  show_underline_4: boolean
}

/**
 * 要約ごとの下線区切りPCのみ
 * @param isDisplay コンポーネントを表示しない場合はfalseを渡してください
 * @param show_underline_1
 * @param show_underline_2
 * @param show_underline_3
 * @param show_underline_4
 * @constructor
 */
export const SummaryUnderLinePC: React.FC<Props> = ({
  isDisplay,
  show_underline_1,
  show_underline_2,
  show_underline_3,
  show_underline_4,
}) => {
  if (!isDisplay) {
    return null
  }
  return (
    <div className="mb30 pc">
      <div className="table mt20 mb10">
        <div className="cell">
          <div className="flexbox">
            <div
              className={`flex1 summary-md ${
                show_underline_1 ? 'bb-1px-gray' : ''
              }`}
            />
            <div className="spc10" />
            <div
              className={`flex1 summary-md ${
                show_underline_2 ? 'bb-1px-gray' : ''
              }`}
            />
          </div>
        </div>
        <div className="spc10"></div>
        <div className="cell">
          <div className="flexbox">
            <div
              className={`flex1 summary-md ${
                show_underline_3 ? 'bb-1px-gray' : ''
              }`}
            />
            <div className="spc10" />
            <div
              className={`flex1 summary-md ${
                show_underline_4 ? 'bb-1px-gray' : ''
              }`}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

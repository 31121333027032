interface Props {
  top_book_num: number
  under_book_num: number
}
export const SummarySkeleton: React.FC<Props> = ({
  top_book_num,
  under_book_num,
}) => {
  return (
    <div>
      {Array(top_book_num)
        .fill(0)
        .map((_, i) => (
          <div key={i} className="py-4 px-2 w-[252px] max-w-sm">
            <div className="flex space-x-4 animate-pulse">
              <div className="w-16 h-20 bg-gray-400"></div>
              <div className="flex-1 py-1 space-y-3">
                <div className="h-5 bg-gray-400"></div>
                <div className="w-1/2 h-4 bg-gray-400"></div>
                <div className="flex space-x-1">
                  <div className="w-2/6 h-4 bg-gray-400"></div>
                  <div className="w-2/6 h-4 bg-gray-400"></div>
                </div>
              </div>
            </div>
          </div>
        ))}
      <div className="flex space-x-5 animate-pulse">
        {Array(under_book_num)
          .fill(0)
          .map((_, i) => (
            <div key={i} className=" w-16 h-20 bg-gray-400"></div>
          ))}
      </div>
    </div>
  )
}

import Link from 'next/link'

interface Props {
  id: string
}
export const SummaryLink: React.FC<Props> = ({ id }) => {
  return (
    <Link href={`/summary/${id}`}>
      <div className="absolute top-0 left-0 w-full h-full border-b-0">
        <span className="hidden">{`/summary/${id}`}</span>
      </div>
    </Link>
  )
}

import { UserInfo, BookPercent, GetCheckResponse } from '@/types'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  CUSTOM_HEADER_FLIER_APP_KEY,
  CUSTOM_HEADER_FLIER_APP_VALUE,
} from '@/utils/constant'

export const apiService = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.BASE_PATH + '/api',
    prepareHeaders: (headers) => {
      headers.set(CUSTOM_HEADER_FLIER_APP_KEY, CUSTOM_HEADER_FLIER_APP_VALUE)
      return headers
    },
  }),
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getUserInfo: builder.query<UserInfo, void>({
      query: () => '/user/info',
    }),
    getUserCheck: builder.query<GetCheckResponse, void>({
      query: () => '/user/check',
    }),
    getUserBookPercent: builder.query<BookPercent[], void>({
      query: () => '/user/book/percent',
    }),
  }),
})
// use + endpointsで設定した名前 + Queryで自動でHooksが作られる
export const {
  useGetUserInfoQuery,
  useGetUserCheckQuery,
  useGetUserBookPercentQuery,
} = apiService

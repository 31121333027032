/* eslint tailwindcss/no-custom-classname: off */
import { ReadStatus as Status } from '@/types'
interface Props {
  status: Status
  className?: string
}
export const ReadStatus: React.FC<Props> = ({ status, className }) => {
  if (status === Status.Finish)
    return <div className={`${className || ''} lvl summary status2`}>読了</div>

  if (status === Status.Reading)
    return (
      <div className={`${className || ''} lvl summary status1`}>読書中</div>
    )

  return <div className={`${className || ''} lvl summary status0`}>未読</div>
}

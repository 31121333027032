/* eslint tailwindcss/no-custom-classname: off */
interface Props {
  isDisplay: boolean
  show_underline_3: boolean
  show_underline_4: boolean
}

/**
 * 要約ごとの下線区切りMOBILE用
 * @param isDisplay コンポーネントを表示しない場合はfalseを渡してください
 * @param show_underline_3
 * @param show_underline_4
 * @constructor
 */
export const SummaryUnderLineSP: React.FC<Props> = ({
  isDisplay,
  show_underline_3,
  show_underline_4,
}) => {
  if (!isDisplay) {
    return null
  }
  return (
    <div className="mt20 mb30 sp">
      <div className="flexbox">
        <div
          className={`flex1 summary-md ${
            show_underline_3 ? 'bb-1px-gray' : ''
          }`}
        />
        <div className="spc10" />
        <div
          className={`flex1 summary-md ${
            show_underline_4 ? 'bb-1px-gray' : ''
          }`}
        />
      </div>
    </div>
  )
}

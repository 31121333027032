import React from 'react'

export const SummarySkeletonL: React.FC = () => {
  return (
    <>
      <div className={'pc'}>
        <div className="flex flex-wrap animate-pulse pc">
          {Array(4)
            .fill(0)
            .map((_, i) => (
              <div
                key={`summary-skelton-pc-${i}`}
                className="p-2 w-1/4 max-w-sm"
              >
                <div className="flex-1 py-1 space-y-3">
                  <div className="h-20 sm:h-40 bg-gray-300 rounded"></div>
                  <div className="w-4/5 h-4 sm:h-6 bg-gray-300 rounded"></div>
                  <div className="w-1/3 h-3 sm:h-4 bg-gray-300 rounded"></div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className={'sp'}>
        <div className="flex flex-wrap animate-pulse">
          {Array(4)
            .fill(0)
            .map((_, i) => (
              <React.Fragment key={`summary-skelton-sp-${i}`}>
                <div className="py-5 px-4 w-full max-w-sm h-[150px]">
                  <div className="flex items-center py-1">
                    <div className="bg-gray-300 rounded"></div>
                    <div className="mr-2 min-w-[72px] max-w-[72px] h-[105px] bg-gray-300 rounded"></div>
                    <div className="mr-3 ml-4 w-full h-12 bg-gray-300 rounded"></div>
                  </div>
                </div>
                <div className="w-full border-b-[1px] border-fl-gray1"></div>
              </React.Fragment>
            ))}
        </div>
      </div>
    </>
  )
}

import { ErrorResponse } from './error'

export enum ReadStatus {
  Finish,
  Reading,
  NotRead,
}

export type ImageSize = '100' | '150' | '300'

export interface Author {
  id: number
  name: string
}

interface Publisher {
  id: number
  name: string
}

export interface Summary {
  id: string
  rank: number
  title: string
  subtitle: string
  subpos: number
  authors: Author[]
  publisher: Publisher
  release: string
  released: string
  recommend: string
  free: number
  ja: boolean
  en: boolean
  new: boolean
  image: string
  chips?: string[]
}

export interface LatestSummary {
  id: string
  title: string
  subtitle: string
  subpos: number
  release: string
  released: string
  recommend: string
  free: number
  ja: boolean
  en: boolean
  new: boolean
  authors: Author[]
  publisher: Publisher
  percent: number
  image: string
  back_image: string
  chips?: string[]
}

export interface LatestSummaryResponse {
  result: boolean
  total: number
  summaries: LatestSummary[]
}

/** @GET /summary/list */
export type GetLatestSummaryResponse = LatestSummaryResponse | ErrorResponse

export interface MasterpieceResponse {
  result: boolean
  total: number
  summaries: LatestSummary[]
}

/** @GET /summary/masterpieces */
export type GetMasterpieceResponse = MasterpieceResponse | ErrorResponse

export interface FreeSummaryResponse {
  result: boolean
  total: number
  summaries: LatestSummary[]
}

/** @GET /summary/free */
export type GetFreeSummaryResponse = FreeSummaryResponse | ErrorResponse

export interface RankingSummary {
  id: string
  rank: number
  title: string
  subtitle: string
  subpos: number
  authors: Author[]
  publisher: Publisher
  release: string
  released: string
  free: number
  ja: boolean
  en: boolean
  new: boolean
  image: string
  chips?: string[]
}
export interface SummaryRankingResponse {
  result: boolean
  total: number
  summaries: RankingSummary[]
}

/** @GET /summary/ranking */
export type GetSummaryRankingResponse = SummaryRankingResponse | ErrorResponse

export interface BackImage {
  large: string
  small: string
}

export interface TopRecommendSummary {
  id: string
  title: string
  subtitle: string
  subpos: number
  authors: Author[]
  free: number
  ja: boolean
  en: boolean
  percent: number
  image: string
  back_image: BackImage
  chips?: string[]
  released?: string
}
export interface TopRecommendSummaryResponse {
  result: boolean
  total: number
  summaries: TopRecommendSummary[]
}

/** @GET /summary/top_recommend */
export type GetTopRecommendSummaryResponse =
  | TopRecommendSummaryResponse
  | ErrorResponse

// eslint-disable-next-line
export const isLatestSummaryResponse = (item: any): item is LatestSummary => {
  return item !== undefined && item?.released
}

// eslint-disable-next-line
export const isRankingSummaryResponse = (item: any): item is RankingSummary => {
  return item !== undefined && item?.rank
}

export interface Category {
  id: number
  name: string
}

export interface Score {
  total: number
  innovation: number
  clarity: number
  applicability: number
}

export interface Writer {
  id: number
  name: string
  image: string
}

export interface SummaryDetail {
  id: number
  title: string
  subtitle: string
  subpos: number
  authors: Author[]
  publisher: Publisher
  publisher_link: string
  pages: number
  price: number
  percent: number
  release: string
  released: string
  categories: Category[]
  score: Score
  review: string
  body: string
  body_pages: number
  remaining_characters: number
  total_characters: number
  notation: string
  about_author: string
  important: string[]
  shops: Shop[]
  writer: Writer
  free: boolean
  ja: boolean
  en: boolean
  new: boolean
  close: boolean
  copyright: string
  image: string
  header_image: string
  header_alt: string
  pdf: string
  voice: string
  chips?: string[]
}

export interface Recommend {
  id: number
  title: string
  authors: Author[]
  free: boolean
  ja: boolean
  en: boolean
  percent: number
  chips?: string[]
  released?: string
}

export interface Shop {
  name: string
  url: string
}

export interface SummaryDetailResponse {
  result: boolean
  summary: SummaryDetail
  recommends: Summary[]
  reason: number
  flag_comment: boolean
  flag_favorite: boolean
}

export type GetSummaryDetailResponse = SummaryDetailResponse | ErrorResponse

export interface GetRecordHistoryAndBooKPercent {
  result: boolean
}

export interface PostBooKPercentResponse {
  result: boolean
}

export interface GetSummaryPermission {
  result: boolean
  permission?: boolean
}

export type GetSummaryPermissionResponse = GetSummaryPermission | ErrorResponse

/** @GET /summary/samples/:plan_id */
export interface GetSummarySamplesResponse {
  result: boolean
  total: number
  summaries: Summary[]
}

export interface SearchedSummaries {
  total: number
  summaries: Summary[]
}

/** @GET /summary/search?keyword= */
export interface GetSearchedSummariesResponse {
  result: boolean
  bytitle: SearchedSummaries
  byauthor: SearchedSummaries
  bybody: SearchedSummaries
  error?: Error
}

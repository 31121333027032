import { getClassNameByRank } from '@/features/global/util'
interface Props {
  rank: number
}
export const RankBadge: React.FC<Props> = ({ rank }) => {
  if (rank === 0) return null
  return (
    <div className={`ranking ${getClassNameByRank(rank)}`}>
      <span>{rank}</span>
    </div>
  )
}

interface Props {
  className?: string
}
export const NewLabel: React.FC<Props> = ({ className = '' }) => {
  return (
    <div
      className={`inline-block py-[2px] px-1 ml-1 w-8 h-[22px] text-[10px] font-bold text-center text-white align-middle bg-fl-yellow ${className}`}
    >
      NEW
    </div>
  )
}

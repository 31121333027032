/* eslint tailwindcss/no-custom-classname: off */
import { ImageSize } from '@/types'
import css from 'styled-jsx/css'

const styles = css`
  .image-shadow {
    box-shadow: 4px 4px 10px rgb(0 0 0 / 20%);
  }
`
interface Props {
  title: string
  id: string
  size?: ImageSize
}
export const SummaryImage: React.FC<Props> = ({ title, id, size }) => {
  const imageSize = size ?? '100'
  return (
    <>
      <div className="sm:table-cell w-full align-top">
        <img
          className="my-0 mx-[30px] sm:mx-6 w-[calc(100%-60px)] sm:w-[calc(100%-48px)] bg-white image-shadow"
          src={`${process.env.IMG_SERVER}/summary/${id}_cover_${imageSize}.jpg`}
          alt={title}
          loading="lazy"
        />
      </div>
      <style jsx>{styles}</style>
    </>
  )
}

import { BookPercent, Srp } from '@/types/user'
import { ReadStatus } from '@/types'

export const getClassNameByRank = (rank: number): string => {
  return rank === 1
    ? 'gold'
    : rank === 2
    ? 'silver'
    : rank === 3
    ? 'bronze'
    : 'medal'
}

export const getReadStatus = (
  bookPercents: BookPercent[],
  summaryId: string
): ReadStatus => {
  if (!bookPercents || bookPercents.length === 0) {
    return ReadStatus.NotRead
  }

  const percent = bookPercents.filter(
    (bookPercent) => `${bookPercent.summary_id}` === summaryId
  )
  if (percent.length === 0) {
    return ReadStatus.NotRead
  }
  if (percent[0].percent === 100) {
    return ReadStatus.Finish
  }
  return ReadStatus.Reading
}

export const getPlaceholder = (
  srp: Srp,
  isMobile: boolean,
  isFacility: boolean
): string => {
  if (
    !isMobile &&
    !isFacility &&
    (srp === Srp.OPEN_SRP || srp === Srp.CLOSE_SRP)
  ) {
    return '書籍名/著者名/ユーザー名'
  }
  if (!isMobile) {
    return '書籍名/著者名'
  }
  return ''
}

/* eslint tailwindcss/no-custom-classname: off */
import {
  LatestSummary,
  RankingSummary,
  Recommend,
  Summary,
  SummaryDetail,
  TopRecommendSummary,
} from '@/types'
import { Summary as SummaryMd } from '@/types/business_group'
import { getReadStatus } from '@/features/global/util'
import { ReadStatus } from '@/components/label/ReadStatus'
import { Free } from '@/components/label/Free'
import { Chip, NewLabel } from '@/components/label'
import { useGetUserBookPercentQuery } from '@/stores/apiService'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
dayjs.locale('ja')

interface Props {
  summary:
    | Summary
    | SummaryMd
    | LatestSummary
    | TopRecommendSummary
    | RankingSummary
    | SummaryDetail
    | Recommend
}
export const SummaryButton: React.FC<Props> = ({ summary }) => {
  const { data } = useGetUserBookPercentQuery()
  const readStatus = getReadStatus(data, `${summary.id}`)

  return (
    <div className="summary-button" data-id={summary.id}>
      <ReadStatus status={readStatus} className="mr-1" />
      {(summary.free === true || summary.free === 1) && (
        <Free className="mr-1" />
      )}
      {summary?.chips &&
        summary.chips.map((value) => (
          <Chip key={value} className="bg-[#E7E0D2]" text={value} />
        ))}
      {summary?.released && dayjs().isSame(dayjs(summary?.released), 'day') && (
        <NewLabel />
      )}
    </div>
  )
}
